export const initialProjects = [
  {
    name: 'First project',
    description:
      "This is your first project. Click on the inputs and see how the signals change. You can add new logic gates by dragging them from the sidebar and connect it with a wire. On topbar you have current project name that you can change. To save the project to the local storage click 'Save' button or just check 'Autosave' checkbox.",
    nodes: [
      {
        id: 'dndnode_dndnode_1',
        position: { x: 203, y: 108.30975970667919 },
        selectable: false,

        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_5',
                  position: { x: 332, y: 230.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: true },
                    logic: 'nor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 332, y: 230.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_5',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_5input1',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_9',
                  position: { x: 339, y: 133.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 339, y: 133.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_9input1',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
        },
        type: 'in',
        width: 44,
        height: 44,
        dragging: false,
        positionAbsolute: { x: 203, y: 108.30975970667919 },
      },
      {
        id: 'dndnode_dndnode_2',
        position: { x: 203, y: 185.5475600733302 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_5',
                  position: { x: 332, y: 230.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: true },
                    logic: 'nor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 332, y: 230.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_5',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_5input2',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_9',
                  position: { x: 339, y: 133.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 339, y: 133.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_9input2',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
        },
        type: 'in',
        width: 44,
        height: 44,
        positionAbsolute: { x: 203, y: 185.5475600733302 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_3',
        position: { x: 203.73165415629637, y: 267.71770455428054 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_7',
                  position: { x: 569, y: 240.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: true, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 569, y: 240.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_3',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_7input2',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
        },
        type: 'in',
        width: 44,
        height: 44,
        positionAbsolute: { x: 203.73165415629637, y: 267.71770455428054 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_5',
        position: { x: 332, y: 230.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: true },
          logic: 'nor',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_10',
                  position: { x: 460, y: 170.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: true },
                    outputs: { output1: true },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 460, y: 170.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_5',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_5output1-dndnode_dndnode_10input2',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_1',
                  position: { x: 203, y: 108.30975970667919 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  dragging: false,
                  positionAbsolute: { x: 203, y: 108.30975970667919 },
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_5',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_5input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_2',
                  position: { x: 203, y: 185.5475600733302 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 203, y: 185.5475600733302 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_5',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_5input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 332, y: 230.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_7',
        position: { x: 569, y: 240.5 },
        selectable: false,
        data: {
          inputs: { input1: true, input2: false },
          outputs: { output1: false },
          logic: 'and',
          connected: {
            after: [
              {
                targetNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_8',
                  position: { x: 701, y: 258.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false },
                    outputs: {},
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'out',
                  positionAbsolute: { x: 701, y: 258.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_7',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_8',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_7output1-dndnode_dndnode_8input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_3',
                  position: { x: 203.73165415629637, y: 267.71770455428054 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: {
                    x: 203.73165415629637,
                    y: 267.71770455428054,
                  },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_3',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_7input2',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_10',
                  position: { x: 460, y: 170.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: true },
                    outputs: { output1: true },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 460, y: 170.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_10',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_10output1-dndnode_dndnode_7input1',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 569, y: 240.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_8',
        position: { x: 701, y: 258.5 },
        selectable: false,
        data: {
          inputs: { input1: false },
          outputs: {},
          logic: 'or',
          connected: {
            after: [],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_7',
                  position: { x: 569, y: 240.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: true, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 569, y: 240.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_7',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_8',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_7output1-dndnode_dndnode_8input1',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'out',
        width: 44,
        height: 44,
        positionAbsolute: { x: 701, y: 258.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_9',
        position: { x: 339, y: 133.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'and',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_10',
                  position: { x: 460, y: 170.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: true },
                    outputs: { output1: true },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 460, y: 170.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_9',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_9output1-dndnode_dndnode_10input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_1',
                  position: { x: 203, y: 108.30975970667919 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  dragging: false,
                  positionAbsolute: { x: 203, y: 108.30975970667919 },
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_9input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_2',
                  position: { x: 203, y: 185.5475600733302 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 203, y: 185.5475600733302 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_9input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 339, y: 133.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_10',
        position: { x: 460, y: 170.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: true },
          outputs: { output1: true },
          logic: 'xor',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_7',
                  position: { x: 569, y: 240.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: true, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 569, y: 240.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_10',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_10output1-dndnode_dndnode_7input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_9',
                  position: { x: 339, y: 133.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 339, y: 133.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_9',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_9output1-dndnode_dndnode_10input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_5',
                  position: { x: 332, y: 230.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: true },
                    logic: 'nor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 332, y: 230.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_5',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_5output1-dndnode_dndnode_10input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 460, y: 170.5 },
        dragging: false,
      },
    ],
    edges: [
      {
        source: 'dndnode_dndnode_1',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_5',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_5input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_2',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_5',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_5input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_3',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_7',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_7input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_7',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_8',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_7output1-dndnode_dndnode_8input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_1',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_9',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_9input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_2',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_9',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_9input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_9',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_10',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_9output1-dndnode_dndnode_10input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_5',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_10',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_5output1-dndnode_dndnode_10input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_10',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_7',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_10output1-dndnode_dndnode_7input1',
        type: 'custom',
      },
    ],
    autosave: true,
    upToDate: true,
  },
  {
    name: 'Block exapmle - 1bit adder',
    description:
      "You can create blocks form logic gates. A block is a collection of logic gates that you can easily reuse in other projects. To create a block your project has to have at least one block input and one block output. You can change names of those components to identify them in block component. Click 'Add block' button on the sidebar and all visible logic gates will be converted to a block that you can drag to the simulation from the sidebar. ",
    nodes: [
      {
        id: 'dndnode_dndnode_1',
        position: { x: 142, y: 112.5 },
        selectable: false,

        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'blockInput',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_6',
                  position: { x: 360, y: 138.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 360, y: 138.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_6',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_6input1',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_9',
                  position: { x: 331, y: 264.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 331, y: 264.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_9input1',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
          name: 'A',
        },
        type: 'custom',
        width: 104,
        height: 85,
        dragging: false,
        positionAbsolute: { x: 142, y: 112.5 },
      },
      {
        id: 'dndnode_dndnode_2',
        position: { x: 141, y: 227.3097597066792 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'blockInput',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_6',
                  position: { x: 360, y: 138.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 360, y: 138.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_6',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_6input2',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_9',
                  position: { x: 331, y: 264.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 331, y: 264.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_9input2',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
          name: 'B',
        },
        type: 'custom',
        width: 104,
        height: 85,
        positionAbsolute: { x: 141, y: 227.3097597066792 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_3',
        position: { x: 140.1426802199906, y: 345.6426802199906 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'blockInput',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_7',
                  position: { x: 600, y: 196.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 600, y: 196.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_3',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_7input2',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_8',
                  position: { x: 480, y: 313.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 480, y: 313.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_3',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_8',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_8input2',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
          name: 'Carry in',
        },
        type: 'custom',
        width: 104,
        height: 85,
        positionAbsolute: { x: 140.1426802199906, y: 345.6426802199906 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_4',
        position: { x: 729, y: 160.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'blockOutput',
          connected: {
            after: [],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_7',
                  position: { x: 600, y: 196.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 600, y: 196.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_7',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_4',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_7output1-dndnode_dndnode_4input1',
                  type: 'custom',
                },
              },
            ],
          },
          name: 'Sum',
        },
        type: 'custom',
        width: 104,
        height: 85,
        positionAbsolute: { x: 729, y: 160.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_5',
        position: { x: 728.0951201466603, y: 279.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'blockOutput',
          connected: {
            after: [],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_10',
                  position: { x: 598, y: 287.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 598, y: 287.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_10',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_5',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_10output1-dndnode_dndnode_5input1',
                  type: 'custom',
                },
              },
            ],
          },
          name: 'Carry out',
        },
        type: 'custom',
        width: 104,
        height: 85,
        positionAbsolute: { x: 728.0951201466603, y: 279.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_6',
        position: { x: 360, y: 138.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'xor',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_7',
                  position: { x: 600, y: 196.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 600, y: 196.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_6',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_6output1-dndnode_dndnode_7input1',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_8',
                  position: { x: 480, y: 313.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 480, y: 313.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_6',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_8',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_6output1-dndnode_dndnode_8input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_1',
                  position: { x: 142, y: 112.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockInput',
                    connected: { after: [], before: [] },
                    name: 'A',
                  },
                  type: 'custom',
                  dragging: false,
                  positionAbsolute: { x: 142, y: 112.5 },
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_6',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_6input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_2',
                  position: { x: 141, y: 227.3097597066792 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockInput',
                    connected: { after: [], before: [] },
                    name: 'B',
                  },
                  type: 'custom',
                  positionAbsolute: { x: 141, y: 227.3097597066792 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_6',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_6input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 360, y: 138.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_7',
        position: { x: 600, y: 196.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'xor',
          connected: {
            after: [
              {
                targetNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_4',
                  position: { x: 729, y: 160.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockOutput',
                    connected: { after: [], before: [] },
                    name: 'Sum',
                  },
                  type: 'custom',
                  positionAbsolute: { x: 729, y: 160.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_7',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_4',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_7output1-dndnode_dndnode_4input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_6',
                  position: { x: 360, y: 138.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 360, y: 138.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_6',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_6output1-dndnode_dndnode_7input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_3',
                  position: { x: 140.1426802199906, y: 345.6426802199906 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockInput',
                    connected: { after: [], before: [] },
                    name: 'Carry in',
                  },
                  type: 'custom',
                  positionAbsolute: {
                    x: 140.1426802199906,
                    y: 345.6426802199906,
                  },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_3',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_7',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_7input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 600, y: 196.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_8',
        position: { x: 480, y: 313.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'and',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_10',
                  position: { x: 598, y: 287.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 598, y: 287.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_8',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_8output1-dndnode_dndnode_10input2',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_6',
                  position: { x: 360, y: 138.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'xor',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 360, y: 138.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_6',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_8',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_6output1-dndnode_dndnode_8input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_3',
                  position: { x: 140.1426802199906, y: 345.6426802199906 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockInput',
                    connected: { after: [], before: [] },
                    name: 'Carry in',
                  },
                  type: 'custom',
                  positionAbsolute: {
                    x: 140.1426802199906,
                    y: 345.6426802199906,
                  },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_3',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_8',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_8input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 480, y: 313.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_9',
        position: { x: 331, y: 264.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'and',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_10',
                  position: { x: 598, y: 287.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 598, y: 287.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_9',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_9output1-dndnode_dndnode_10input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_1',
                  position: { x: 142, y: 112.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockInput',
                    connected: { after: [], before: [] },
                    name: 'A',
                  },
                  type: 'custom',
                  dragging: false,
                  positionAbsolute: { x: 142, y: 112.5 },
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_1',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_9input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_2',
                  position: { x: 141, y: 223.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockInput',
                    connected: { after: [], before: [] },
                    name: 'B',
                  },
                  type: 'custom',
                  positionAbsolute: { x: 141, y: 223.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_2',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_9',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_9input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 331, y: 264.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_10',
        position: { x: 598, y: 287.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 104,
                  height: 85,
                  id: 'dndnode_dndnode_5',
                  position: { x: 728.0951201466603, y: 279.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'blockOutput',
                    connected: { after: [], before: [] },
                    name: 'Carry out',
                  },
                  type: 'custom',
                  positionAbsolute: { x: 728.0951201466603, y: 279.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_10',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_5',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_10output1-dndnode_dndnode_5input1',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_9',
                  position: { x: 331, y: 264.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 331, y: 264.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_9',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_9output1-dndnode_dndnode_10input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_8',
                  position: { x: 480, y: 313.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'and',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 480, y: 313.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_8',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_10',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_8output1-dndnode_dndnode_10input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 598, y: 287.5 },
        dragging: false,
      },
    ],
    edges: [
      {
        source: 'dndnode_dndnode_1',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_6',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_6input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_1',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_9',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_1output1-dndnode_dndnode_9input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_2',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_6',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_6input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_2',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_9',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_2output1-dndnode_dndnode_9input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_6',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_7',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_6output1-dndnode_dndnode_7input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_6',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_8',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_6output1-dndnode_dndnode_8input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_3',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_7',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_7input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_3',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_8',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_3output1-dndnode_dndnode_8input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_9',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_10',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_9output1-dndnode_dndnode_10input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_8',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_10',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_8output1-dndnode_dndnode_10input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_7',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_4',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_7output1-dndnode_dndnode_4input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_10',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_5',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_10output1-dndnode_dndnode_5input1',
        type: 'custom',
      },
    ],
    autosave: true,
    upToDate: true,
  },
  {
    name: 'Display example',
    description:
      'You can use 7 segment display in your projects. Every input in the display represents one segment. You can create logic to control the display',
    nodes: [
      {
        id: 'dndnode_dndnode_18',
        position: { x: 657, y: 169.5 },
        selectable: false,
        data: {
          inputs: {
            input1: false,
            input2: false,
            input3: false,
            input4: false,
            input5: false,
            input6: false,
            input7: false,
          },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [],
            before: [
              {
                sourceNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_23',
                  position: { x: 512, y: 172.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 512, y: 172.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_23',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_23output1-dndnode_dndnode_18input2',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_26',
                  position: { x: 287, y: 285.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 287, y: 285.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_26',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input3',
                  id: 'reactflow__edge-dndnode_dndnode_26output1-dndnode_dndnode_18input3',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_27',
                  position: { x: 284, y: 349.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 284, y: 349.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_27',
                  position: { x: 284, y: 349.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 284, y: 349.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input7',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input7',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_27',
                  position: { x: 284, y: 349.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 284, y: 349.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input4',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input4',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_27',
                  position: { x: 284, y: 349.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 284, y: 349.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input5',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input5',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'display',
        width: 73,
        height: 112,
        positionAbsolute: { x: 657, y: 169.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_23',
        position: { x: 512, y: 172.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 73,
                  height: 112,
                  id: 'dndnode_dndnode_18',
                  position: { x: 657, y: 169.5 },
                  selectable: false,
                  data: {
                    inputs: {
                      input1: false,
                      input2: false,
                      input3: false,
                      input4: false,
                      input5: false,
                      input6: false,
                      input7: false,
                    },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'display',
                  positionAbsolute: { x: 657, y: 169.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_23',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_23output1-dndnode_dndnode_18input2',
                  type: 'custom',
                },
              },
            ],
            before: [
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_26',
                  position: { x: 287, y: 285.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 287, y: 285.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_26',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_23',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_26output1-dndnode_dndnode_23input1',
                  type: 'custom',
                },
              },
              {
                sourceNode: {
                  width: 44,
                  height: 44,
                  id: 'dndnode_dndnode_27',
                  position: { x: 284, y: 349.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'in',
                  positionAbsolute: { x: 284, y: 349.5 },
                  dragging: false,
                },
                edgeBefore: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_23',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_23input2',
                  type: 'custom',
                },
              },
            ],
          },
        },
        type: 'custom',
        width: 80,
        height: 50,
        positionAbsolute: { x: 512, y: 172.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_26',
        position: { x: 287, y: 285.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_23',
                  position: { x: 512, y: 172.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 512, y: 172.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_26',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_23',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_26output1-dndnode_dndnode_23input1',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 73,
                  height: 112,
                  id: 'dndnode_dndnode_18',
                  position: { x: 657, y: 169.5 },
                  selectable: false,
                  data: {
                    inputs: {
                      input1: false,
                      input2: false,
                      input3: false,
                      input4: false,
                      input5: false,
                      input6: false,
                      input7: false,
                    },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'display',
                  positionAbsolute: { x: 657, y: 169.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_26',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input3',
                  id: 'reactflow__edge-dndnode_dndnode_26output1-dndnode_dndnode_18input3',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
        },
        type: 'in',
        width: 44,
        height: 44,
        positionAbsolute: { x: 287, y: 285.5 },
        dragging: false,
      },
      {
        id: 'dndnode_dndnode_27',
        position: { x: 284, y: 349.5 },
        selectable: false,
        data: {
          inputs: { input1: false, input2: false },
          outputs: { output1: false },
          logic: 'or',
          connected: {
            after: [
              {
                targetNode: {
                  width: 80,
                  height: 50,
                  id: 'dndnode_dndnode_23',
                  position: { x: 512, y: 172.5 },
                  selectable: false,
                  data: {
                    inputs: { input1: false, input2: false },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'custom',
                  positionAbsolute: { x: 512, y: 172.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_23',
                  targetHandle: 'input2',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_23input2',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 73,
                  height: 112,
                  id: 'dndnode_dndnode_18',
                  position: { x: 657, y: 169.5 },
                  selectable: false,
                  data: {
                    inputs: {
                      input1: false,
                      input2: false,
                      input3: false,
                      input4: false,
                      input5: false,
                      input6: false,
                      input7: false,
                    },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'display',
                  positionAbsolute: { x: 657, y: 169.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input1',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input1',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 73,
                  height: 112,
                  id: 'dndnode_dndnode_18',
                  position: { x: 657, y: 169.5 },
                  selectable: false,
                  data: {
                    inputs: {
                      input1: false,
                      input2: false,
                      input3: false,
                      input4: false,
                      input5: false,
                      input6: false,
                      input7: false,
                    },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'display',
                  positionAbsolute: { x: 657, y: 169.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input7',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input7',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 73,
                  height: 112,
                  id: 'dndnode_dndnode_18',
                  position: { x: 657, y: 169.5 },
                  selectable: false,
                  data: {
                    inputs: {
                      input1: false,
                      input2: false,
                      input3: false,
                      input4: false,
                      input5: false,
                      input6: false,
                      input7: false,
                    },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'display',
                  positionAbsolute: { x: 657, y: 169.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input4',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input4',
                  type: 'custom',
                },
              },
              {
                targetNode: {
                  width: 73,
                  height: 112,
                  id: 'dndnode_dndnode_18',
                  position: { x: 657, y: 169.5 },
                  selectable: false,
                  data: {
                    inputs: {
                      input1: false,
                      input2: false,
                      input3: false,
                      input4: false,
                      input5: false,
                      input6: false,
                      input7: false,
                    },
                    outputs: { output1: false },
                    logic: 'or',
                    connected: { after: [], before: [] },
                  },
                  type: 'display',
                  positionAbsolute: { x: 657, y: 169.5 },
                  dragging: false,
                },
                edgeAfter: {
                  source: 'dndnode_dndnode_27',
                  sourceHandle: 'output1',
                  target: 'dndnode_dndnode_18',
                  targetHandle: 'input5',
                  id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input5',
                  type: 'custom',
                },
              },
            ],
            before: [],
          },
        },
        type: 'in',
        width: 44,
        height: 44,
        positionAbsolute: { x: 284, y: 349.5 },
        dragging: false,
      },
    ],
    edges: [
      {
        source: 'dndnode_dndnode_23',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_18',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_23output1-dndnode_dndnode_18input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_26',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_23',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_26output1-dndnode_dndnode_23input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_27',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_23',
        targetHandle: 'input2',
        id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_23input2',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_26',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_18',
        targetHandle: 'input3',
        id: 'reactflow__edge-dndnode_dndnode_26output1-dndnode_dndnode_18input3',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_27',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_18',
        targetHandle: 'input1',
        id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input1',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_27',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_18',
        targetHandle: 'input7',
        id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input7',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_27',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_18',
        targetHandle: 'input4',
        id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input4',
        type: 'custom',
      },
      {
        source: 'dndnode_dndnode_27',
        sourceHandle: 'output1',
        target: 'dndnode_dndnode_18',
        targetHandle: 'input5',
        id: 'reactflow__edge-dndnode_dndnode_27output1-dndnode_dndnode_18input5',
        type: 'custom',
      },
    ],
    autosave: true,
    upToDate: true,
  },
]
